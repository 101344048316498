<template>
  <div class="basket-container">
    <div class="basket-header">
      <h3>Your Basket</h3>
      <button class="close-btn" @click="$emit('close')">×</button>
    </div>
    <div class="basket-summary">
      <p>Total Cost: £{{ total.toFixed(2) }}</p>
      <p>Total Weight: {{ weight.toFixed(0) }} grams</p>
      <button class="checkout-btn" @click="handleCheckout">Checkout</button>
    </div>
    <div class="basket-items">
      <div v-for="item in items" :key="item.id" class="basket-item">
        <img :src="item.imageUrl" :alt="item.name" class="item-image">
        <div class="item-details">
          <h4>{{ item.name }}</h4>
          <p>Quantity: {{ item.quantity }} - ({{ item.selectedOption ? item.selectedOption.type : 'Standard' }})</p>
          <p>Price: £{{ getPrice(item).toFixed(2) }}</p>
        </div>
        <button class="remove-btn" @click="removeFromBasket(item.id)">Remove</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  name: 'BasketComponent',
  setup() {
    const router = useRouter();
    return { router };
  },
  computed: {
    ...mapGetters('basket', ['items', 'total', 'weight']),
  },
  methods: {
    ...mapActions('basket', ['removeItem']),
    removeFromBasket(itemId) {
      this.removeItem(itemId);
    },
    getPrice(item) {
      return (item.selectedOption && item.selectedOption.price) ? item.selectedOption.price : item.price;
    },
    handleCheckout() {
      this.$emit('close'); // Emit 'close' to close the basket
      this.router.push('/checkout'); // Navigate to checkout page programmatically
    },
  },
};
</script>

<style scoped>
.basket-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 400px; /* Default width for larger screens */
  height: 100vh;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.basket-header, .basket-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #f8f8f8;
  border-bottom: 1px solid #eee;
}

.close-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.basket-items {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
}

.basket-item {
  display: flex;
  margin-bottom: 1rem;
}

.item-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 1rem;
}

.item-details {
  flex: 1;
}

.remove-btn, .checkout-btn {
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.checkout-btn {
  background-color: #EFA00B;
  margin-left: 25px;
  border-radius: 15px;
}

.basket-summary {
  padding: 1rem;
  border-top: 1px solid #eee;
  text-align: right;
}

@media (max-width: 999px) {
  .basket-container {
    width: 100%; /* Full width on smaller screens */
    right: 0;
  }

  .basket-header h3, .basket-summary p {
    font-size: smaller; /* Adjust font size for smaller screens */
  }

  .item-image {
    width: 60px; /* Smaller image size on mobile */
    height: 60px;
  }

  .remove-btn, .checkout-btn {
    padding: 0.3rem 0.6rem; /* Smaller buttons on mobile */
  }
}

</style>

  