import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

const AuthService = {
  async login(user) {
    const response = await axios.post(`${API_URL}/login.php`, user, {
      withCredentials: true
    });
    return response.data;
  },

  async register(user) {
    const response = await axios.post(`${API_URL}/signup.php`, user);
    return response.data;
  },

  async logout() {
    await axios.post(`${API_URL}/logout.php`, {}, {
      withCredentials: true
    });
  }
};

export default AuthService;

