<template>
  <div id="app">
    <SidebarComponent />
    <main>
      <router-view></router-view>
      <!-- Basket toggle button and the BasketComponent itself -->
      <button :class="{'basket-toggle': true, 'item-added': itemAdded}" @click="toggleBasket">
        Basket ({{ itemCount }})
      </button>
      <BasketComponent v-if="isBasketOpen" @close="toggleBasket" />
    </main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SidebarComponent from './components/common/Sidebar.vue';
import BasketComponent from './components/common/Basket.vue';

export default {
  name: 'App',
  components: {
    SidebarComponent,
    BasketComponent,
  },
  data() {
    return {
      isBasketOpen: false,
      itemAdded: false,
    };
  },
  computed: {
    ...mapGetters('basket', ['items']),
    itemCount() {
      return this.items.reduce((total, item) => total + item.quantity, 0);
    },
  },
  methods: {
    toggleBasket() {
      console.log("Toggling basket visibility");
      this.isBasketOpen = !this.isBasketOpen;
      this.itemAdded = false;
    },
    addItemEffect() {
      this.itemAdded = true;
      setTimeout(() => this.itemAdded = false, 300); // Effect duration
    },
  },
  watch: {
    'items.length': function(newVal, oldVal) {
      if (newVal > oldVal) this.addItemEffect(); // Trigger effect on item addition
    }
  },
};
</script>

<style scoped>
.basket-toggle {
  font-size: 1.25rem;
  padding: 1em 2em;
  background-color: #EFA00B;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0px 10px 20px rgba(0,0,0,0.2);
  transition: all 0.3s ease;
  position: fixed;
  right: 15px;
  bottom: 15px;
  padding: 10px 15px;
  overflow: hidden;
  margin: 5px;
  z-index: 1;
}

.basket-toggle:hover, .basket-toggle.item-added {
  background-color: #cc8400;
  transform: translateY(-20px);
}

@media (max-width: 999px) { 
  .basket-toggle {
    right: auto;
    left: 15px;
    top: 15px;
    bottom: auto;
  }
}
</style>
