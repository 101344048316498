// Import createRouter and createWebHistory from vue-router
import { createRouter, createWebHistory } from 'vue-router';

// Define route components.
// The "import" function is used for lazy loading of components, improving load time and performance.

const CustomerLogin = () => import('./components/pages/CustomerLogin.vue');
const MyAccount = () => import('./components/pages/MyAccount.vue');
const CustomerHome = () => import('./components/pages/Home.vue');
const Checkout = () => import('./components/pages/Checkout.vue');
const CheckoutSuccess = () => import('./components/pages/CheckoutSuccess.vue');


// Define routes
// Each route should map to a component. The "component" can either be an actual component
// or a function that returns a component.
const routes = [
  { path: '/customerlogin', name: 'CustomerLogin', component: CustomerLogin },
  { path: '/my-account', name: 'MyAccount', component: MyAccount },
  { path: '/', name: 'CustomerHome', component: CustomerHome },
  { path: '/checkout', name: 'Checkout', component: Checkout },
  { path: '/checkout-success', name: 'CheckoutSuccess', component: CheckoutSuccess }


  // You can add more routes here as needed.
];

// Create the router instance and pass the `routes` option
const router = createRouter({
  history: createWebHistory('/'), // Adjust based on your actual base URL if needed
  routes,
});

export default router;

