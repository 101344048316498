<template>
  <div class="burger-menu" @click="toggleSidebar">
    <div v-if="!sidebarOpen"></div> <!-- Only show lines if the sidebar is not open -->
    <div v-if="!sidebarOpen"></div>
    <div v-if="!sidebarOpen"></div>
    <div v-if="sidebarOpen" class="close">&times;</div> <!-- Show close button when sidebar is open -->
  </div>

  <div class="sidebar" :class="{'is-open': sidebarOpen}">
    <div class="title-container" @click="navigateAndCloseSidebar(goToTopOrHomePage)">
      <img src="@/assets/images/Title.png" alt="Snack Shack Title" class="title-image">
    </div>
    <div class="nav-container">
      <nav class="nav flex-column">
        <div @click="navigateAndCloseSidebar(goToAboutUs)" class="nav-link">Snack Shack's Story</div>
        <div @click="navigateAndCloseSidebar(goToFeaturedProductsSection)" class="nav-link">Benefits of Natural</div>
        <div @click="navigateAndCloseSidebar(goToProductsSection)" class="nav-link">Our Products</div>
        <router-link v-if="!isAuthenticated" to="/customerlogin" class="nav-link" @click="toggleSidebar">Login</router-link>
        <router-link v-if="isAuthenticated" to="/my-account" class="nav-link" @click="toggleSidebar">My Account</router-link>
        <div v-if="isAuthenticated" @click="logout" class="nav-link logout-link">Logout</div>
        <!-- Additional links can be added here -->
      </nav>
    </div>
    <!-- Logo Container at the Bottom -->
    <div class="logo-bottom-container">
      <img src="@/assets/images/Logo2.png" alt="Snack Shack Logo" class="logo-image">
    </div>
  </div>
</template>

<script>
export default {
  name: 'SidebarComponent',
  data() {
    return {
      sidebarOpen: false, // Initially the sidebar is closed
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['auth/isAuthenticated'];
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout');
      this.toggleSidebar(); // Close the sidebar when logging out
      this.$router.push('/customerlogin');
    },
    goToProductsSection() {
      this.$router.push('/').then(() => {
        this.$nextTick(() => {
          const section = document.querySelector('.products-section');
          if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
          }
        });
      });
    },
    goToAboutUs() {
      this.$router.push('/').then(() => {
        this.$nextTick(() => {
          const section = document.querySelector('.about-us-section');
          if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
          }
        });
      });
    },
    goToFeaturedProductsSection() {
      if (this.$route.path !== '/') {
        this.$router.push('/').then(() => {
          this.scrollToFeaturedProducts();
        });
      } else {
        this.scrollToFeaturedProducts();
      }
    },
    scrollToFeaturedProducts() {
      this.$nextTick(() => {
        const section = document.querySelector('.featured-products');
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      });
    },
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen; // Toggle the sidebar state
    },
    navigateAndCloseSidebar(navigationFunction) {
      navigationFunction.call(this);
      this.toggleSidebar();
    },
    goToTopOrHomePage() {
      if (this.$route.path === '/') {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        this.$router.push('/');
      }
    }
  }
}
</script>

<style scoped>
.sidebar {
  width: 250px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #BA7C2F; /* Darker shade for contrast */
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.title-container {
  background-color: #BA7C2F; /* Slightly lighter shade for the top area */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo-image, .title-image {
  max-width: 200px;
  height: auto;
  display: block;
  margin: auto;
  padding: 20px;
}

.nav-container {
  flex: 1;
}

.nav-link {
  text-decoration: none;
  color: #081418;
  padding: 12px 20px; /* Slightly increased padding for depth */
  display: block;
  transition: background-color 0.3s, color 0.3s;
  background: linear-gradient(to right, #BA7C2F 80%, #A1722E 20%); /* Subtle gradient */
  border-radius: 4px; /* Rounded corners for a soft look */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), /* Outer shadow for depth */
              inset 1px 1px 2px rgba(255, 255, 255, 0.4), /* Inner light for bevel */
              inset -1px -1px 2px rgba(0, 0, 0, 0.4); /* Inner shadow for bevel */
}

.nav-link:hover, .nav-link.active {
  background: #081418;
  color: #FFFFFF;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), /* Maintain outer shadow */
              inset 0px 0px 7px rgba(255, 255, 255, 0.5); /* Bright inner glow on hover */
}


/* Styles for the burger menu */
.burger-menu {
  display: none;
}

.burger-menu .close {
  font-size: 24px; /* Adjust as necessary */
  display: block; /* Show the close icon */
}

/* Burger Menu Lines */
.burger-menu div {
  width: 30px;
  height: 3px;
  background-color: #EFA00B;
  transition: all 0.3s ease;
}

/* Hide sidebar by default on mobile and show burger menu */
@media (max-width: 999px) {
  .sidebar {
    width: 0;
    visibility: hidden;
    opacity: 0;
    transition: width 0.3s ease, opacity 0.3s ease, visibility 0s 0.3s;
  }

  .sidebar.is-open {
    width: 250px;
    visibility: visible;
    opacity: 1;
    transition: width 0.3s ease, opacity 0.3s ease;
  }

  .content.sidebar-closed {
    margin-left: 0; /* Adjust as necessary for your layout */
  }
  
  .burger-menu {
    display: block; /* Show the burger menu on small screens */
  }

  .burger-menu {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px;
    z-index: 3; /* Above the sidebar */
    position: fixed;
    top: 15px;
    right: 15px;
  }
}
</style>
