const state = {
    items: [], // Holds the items in the basket
  };
  
  const mutations = {
    ADD_ITEM(state, item) {
      const existingItem = state.items.find(product => product.id === item.id && product.selectedOption.type === item.selectedOption.type);
      if (existingItem) {
        // If the item with the same option already exists, increase its quantity
        existingItem.quantity += item.quantity;
      } else {
        // If the item doesn't exist or different option selected, add it
        state.items.push({
          ...item,
          quantity: item.quantity
        });
      }
    },
    REMOVE_ITEM(state, itemId) {
      const index = state.items.findIndex(item => item.id === itemId);
      if (index !== -1) {
        // If the item exists, remove it from the array
        state.items.splice(index, 1);
      }
    },
    CLEAR_BASKET(state) {
      state.items = [];
    },
  };
  
  const actions = {
    addItem({ commit }, item) {
      commit('ADD_ITEM', item);
    },
    removeItem({ commit }, itemId) {
      commit('REMOVE_ITEM', itemId);
    },
    clearBasket({ commit }) {
      commit('CLEAR_BASKET');
    },
  };
  
  const getters = {
    items: state => state.items,
    total: state => {
      return state.items.reduce((acc, item) => {
        // Check if price is a number; otherwise, default to item price
        const price = Number(item.selectedOption && item.selectedOption.price) || item.price;
        return acc + (price * item.quantity);
      }, 0);
    },
    weight: state => {
      return state.items.reduce((acc, item) => {
        // Check if weight is a number; otherwise, default to 0 or an appropriate default weight
        const weight = Number(item.selectedOption && item.selectedOption.weight) || 0;
        return acc + (weight * item.quantity);
      }, 0);
    },
  };   
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
  };
  
  