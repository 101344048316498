import { createStore } from 'vuex';
import basket from './modules/basket';
import auth from './modules/auth'; // Import the auth module

export default createStore({
  modules: {
    basket,
    auth, // Add auth module to the store
  },
});

