import authService from '../../services/authService';

const state = {
  userToken: null,
  userInfo: {},
  isLoading: false,
  error: null,
};

const mutations = {
  AUTH_REQUEST(state) {
    state.isLoading = true;
    state.error = null;
  },
  AUTH_SUCCESS(state, { token, userInfo }) {
    state.isLoading = false;
    state.userToken = token;
    state.userInfo = userInfo;
    localStorage.setItem('userToken', token); // Store token in localStorage
    localStorage.setItem('userInfo', JSON.stringify(userInfo)); // Store userInfo in localStorage
  },
  LOGOUT(state) {
    state.userToken = null;
    state.userInfo = {};
    localStorage.removeItem('userToken'); // Remove token from localStorage
    localStorage.removeItem('userInfo'); // Remove userInfo from localStorage
  },
  AUTH_ERROR(state, error) {
    state.isLoading = false;
    state.error = error;
  },
};

const actions = {
  async login({ commit }, userCredentials) {
    commit('AUTH_REQUEST');
    try {
      const data = await authService.login(userCredentials);
      commit('AUTH_SUCCESS', {
        token: data.accessToken,
        userInfo: data.user, // Adjust according to the actual response structure
      });
      localStorage.setItem('user', JSON.stringify(data)); // Store user data and token in localStorage
      localStorage.setItem('token', data.accessToken);
    } catch (error) {
      commit('AUTH_ERROR', error);
      localStorage.removeItem('user'); // Ensure user data is removed if login fails
      throw error; // Rethrow the error so you can catch it in your component
    }
  },
  logout({ commit }) {
    commit('LOGOUT');
    localStorage.removeItem('user');
    localStorage.removeItem('token');
  },
  tryAutoLogin({ commit }) {
    const token = localStorage.getItem('userToken');
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (token && userInfo) {
      commit('AUTH_SUCCESS', { token, userInfo });
    }
  },
  // Additional authentication actions like register can be added here.
};

const getters = {
  isAuthenticated: state => !!state.userToken,
  userInfo: state => state.userInfo,
  family: state => state.userInfo.family,
  isLoading: state => state.isLoading,
  error: state => state.error,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

