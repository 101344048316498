import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Assume you have a router.js file setup for Vue Router
import store from './store';


// Importing main stylesheet
import './assets/css/main.css';

// Creating the Vue app instance
const app = createApp(App);

// Use plugins
app.use(router);
app.use(store);

router.beforeEach((to, from, next) => {
    const isAuthenticated = store.getters['auth/isAuthenticated'];
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  
    if (requiresAuth && !isAuthenticated) {
      next('/customerlogin');
    } else {
      next();
    }
  });
  
  store.dispatch('auth/tryAutoLogin').then(() => {
    app.mount('#app');
  });
